import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
import PsiCommunityChart from './chart/psi-community-chart.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <blockquote>
      <p parentName="blockquote">{`The learning experience at PSI is shaped by a spirit of community, whereby students, teachers and parents work together to provide the ingredients necessary to create independent learners and global citizens in ‘A Place Where We Belong.’`}</p>
    </blockquote>
    <p>{`We anticipate that PSI students will be active, inquiring learners in pursuit of their individual journey towards personal excellence. Almost every graduating student moves on to tertiary education. At the same time, a broad range of opportunities and experiences are valued at PSI. These are provided through our Extra Curricular Activities Programme, and special events. Our strong commitment to community service and action is demonstrated via our many service learning projects, and an involvement in the Central and Eastern European Schools Association (CEESA) - a collaborative network of schools in this region.`}</p>
    <PsiCommunityChart mdxType="PsiCommunityChart" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      